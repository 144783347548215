<template>
  <div>
    <div class="top-add" v-show="appInfo">
      <div class="app-info">

        <van-icon name="cross" class="close-icon" @click="closeApp" />
        <img src="~img/common/logo.png" alt="">
        <div class="name-info">
          <h3>OmniRead</h3>
          <p>https://www.omniread.cc</p>
        </div>
        <span class="download" @click="handleDownloadFn">
          <span>Open APP</span>
        </span>
      </div>
      <div class="add-info" @click="addInfo">+ {{$t('msg.addScreen')}}</div>
    </div>

    <van-popup v-model="showAdd" position="bottom" @close="closeAdd" @click="closeAdd">
      <div class="popup-content">
        <div class="bot-info">
          <span>{{$t('msg.tap')}}</span>
          <img src="~img/common/share.jpg" alt="">
          <span>{{$t('msg.then')}}</span>
          <img src="~img/common/add.jpg" alt="">
          <span class="add-home">{{$t('msg.addScreen')}}</span>
        </div>
        <div class="note-icon">
          <div class="trangle"></div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { GetDownloadUrlApi } from "@/http/api";
export default ({
  data() {
    return {
      showAdd: false, // 是否显示弹窗
      appInfo: true,
      android_url: '',
      ios_url: '',
    }
  },
  created() {
    GetDownloadUrlApi().then(res => {
      this.android_url = res.data.android_url
      this.ios_url = res.data.ios_url
    })
  },
  methods: {
    closeAdd() {
      this.showAdd = false
    },
    closeApp() {
      this.appInfo = false
    },
    addInfo() { // 点击添加到主屏幕
      this.showAdd = true
    },
    handleDownloadFn() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // 检查 iOS  
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.open(this.ios_url, '_blank')
      }

      // 检查 Android  
      if (/android/i.test(userAgent)) {
        window.open(this.android_url, '_blank')
      }
    }
  },
  mounted() {
  }
})
</script>

<style scoped lang="scss">
/deep/ .van-popup {
  background-color: transparent;
  height: 100%;
}
.top-add {
  .app-info {
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 1rem;
    img {
      width: 2rem;
      height: 2rem;
      border-radius: 2rem;
    }
    .name-info {
      height: 2rem;
      text-align: left;
      margin-left: 0.5rem;
      width: 84%;
      h3 {
        font-weight: 700;
        font-size: 0.93rem;
      }
      p {
        font-size: 0.65rem;
        color: #999;
        line-height: 0.7rem;
      }
    }
    .close-icon {
      line-height: 2rem;
      font-size: 1rem;
      color: #ccc;
      margin-right: 0.5rem;
    }
  }

  .add-info {
    color: white;
    background-color: #1d8bfa;
    height: 2rem;
    line-height: 2rem;
    font-size: 0.8rem;
    font-weight: 500;
  }
}

.download {
  width: 35.282vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.077vw;
  height: 6.666vw;
  line-height: 6.666vw;
  border-radius: 25.846vw;
  background: #9b8cff;
  box-shadow: 0 0.3125rem 0.625rem #cdb7ff33;
  color: #fff;
}
.popup-content {
  .bot-info {
    background-color: #fff;
    position: absolute;
    bottom: 2rem;
    width: 90%;
    margin-left: 5%;
    height: 3rem;
    line-height: 3rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;
    font-size: 0.9rem;
    img {
      width: 1rem;
      height: 1rem;
      margin: 0 0.2rem;
    }
    .add-home {
      color: #333;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }

  .note-icon {
    position: absolute;
    bottom: calc(1rem + 1px);
    left: calc(50% - 0.5rem);
    font-size: 0.7rem;
    .trangle {
      width: 0px;
      height: 0px;
      font-size: 0.8rem;
      border: 0.5rem solid #fff;
      border-top-color: white;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }
}
</style>
